<template>
    <div class="pt-1 px-3">
        
        <div class="">
            <router-link to="/bussiness/training" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </router-link>
        </div>

         <!-- NAV HEADER -->
         <!-- END NAV -->
         <div class="d-flex mt-3">
            <ul class="m-auto nav nav-pills">
                <li class="nav-item">
                    <router-link class="nav-link font-weight-bold" 
                        style="border-radius: 10px 0 0 10px;background-color: #1EB4F1;color:white;font-size: 16px;"
                        :to="`/bussiness/training/form/${id}`">Form Details</router-link>
                </li>
                <li class="nav-item" v-if="id">
                    <router-link class="nav-link font-weight-bold" 
                        style="color:#1B628C;background-color: white;border-radius: 0 10px 10px 0;font-size: 16px;" 
                        :to="`/bussiness/training/form/${id}/schedules`"
                    >
                    Schedules</router-link>
                </li>
                <li class="nav-item" v-else>
                    <button class="nav-link font-weight-bold" 
                        style="color:white;background-color: grey;border-radius: 0 10px 10px 0;font-size: 16px;" 
                        disabled
                    >
                    Schedules</button>
                </li>
            </ul>
        </div>

        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Training Form</h3>

            <div class="mt-2">
                <label class="h3 text-white">Service Name</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-notepad"></i></span>
                    <input class="form-control" placeholder="Service Name" v-model="tr_name">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.tr_name && isSubmit" style="margin-top: .5em;">Name not valid</div>
            </div>

            <div class="mt-2">
                <label class="h3 text-white">Details</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-comment"></i></span>
                    <textarea class="form-control" placeholder="Details" v-model="tr_details"></textarea>
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.tr_details && isSubmit" style="margin-top: .5em;">Description not valid</div>
            </div>

            <div class="mt-2">
                <label class="h3 text-white">Price</label>
                <div class="input-group">
                    <span class="input-group-text text-white"><i class="bx bx-dollar"></i></span>
                    <input class="form-control" placeholder="Price" type="number" v-model="tr_price">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.tr_price && isSubmit" style="margin-top: .5em;">Price not valid</div>
            </div> 

            <div class="mt-2 w-100 d-flex flex-column justify-content-center" style="gap:10px">
                <button class="btn text-white flex-fill" style="background-color: #187294;height:50px;font-size: 18px;"
                    @click="onSubmit()">
                    Submit
                </button>
            </div>

        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'

let cookies = new Cookies()

export default {
    computed: {
        training(){
            return store.state.training.TRAINING
        },
        user(){
            return store.state.auth.USER
        },
        valid(){
            return {
                tr_name : this.tr_name?.length > 0,
                tr_details : this.tr_details?.length > 0,  
                tr_price : this.tr_price?.length > 0,  
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    watch: {
        training(val){
            if(val){
                if(val.user_id != this.user.user_id){
                    this.$router.push('/homepage')
                }

                this.tr_name = val.tr_name
                this.tr_details = val.tr_details
                this.tr_price = val.tr_price
            }
        }
    },
    methods: {
        onSubmit() {
            this.isSubmit = true
            if(this.allValid){
                let payload = new FormData()
                payload.append('inp[tr_name]', this.tr_name)
                payload.append('inp[tr_details]', this.tr_details)
                payload.append('inp[tr_price]', this.tr_price)
                payload.append('inp[user_id]', cookies.get('token'))
                if(this.id){
                    payload.append('id', this.id)
                }
                store.dispatch('training/SaveTraining', payload).then((res) => {
                    this.$router.push(`/bussiness/training/form/${res.tr_id}/schedules`)
                })
            }
        }
    },
    mounted(){
        if(this.id){
            store.dispatch('training/GetTraining', this.id)
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            tr_name : '',
            tr_details: '',
            tr_price : '',
            isSubmit: false
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input, .input-group textarea {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 40px;
    color: white;
    background-color: transparent !important;
}
</style>